<div class="component-structure flex-row">
  <igx-input-group displayDensity="compact" theme="bootstrap">
    <input #input igxInput name="customerSearch" type="text" [igxTooltipTarget]="customerSearchRef">
    <label igxLabel for="customerSearch">Søk</label>
  </igx-input-group>
  <button class="btn" (click)="searchCustomer(input)" igxButton="raised" igxButtonColor="white"
    [style.background]="'#0e0e0e'" igxRipple="white"> søk </button>
  <!-- <button class="btn" (click)="reset(input)" igxButton="raised" igxButtonColor="white" [style.background]="#0e0e0e"
    igxRipple="white"> reset </button> -->

</div>
<igx-grid class="grid-fill m-top" igxPreventDocumentScroll #grid [data]="data"
  [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" (selected)="onRowSelected($event)"
  [allowFiltering]="true" [hideRowSelectors]="true">
  <igx-column field="Name" header="Navn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="!isMobile" field="ShortName" header="Kortnavn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column [width]="isMobile ? '80px': ''" field="MaxUsers" header="Max brukere" [sortable]="true"
    [dataType]="'number'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column [width]="isMobile ? '100px': ''" field="EndDate" header="Stopp dato" [sortable]="true" [dataType]="'date'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column [width]="isMobile ? '80px': ''" field="CountActiveUsers" header="Aktive brukere" [sortable]="true"
    [dataType]="'number'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="grid.totalRowsCountAfterFilter" [total]="data.length" />
  </igx-grid-footer>
</igx-grid>



<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>

<pre class="tooltip" #customerSearchRef="tooltip" igxTooltip>
  {{toolTips.search}}
</pre>