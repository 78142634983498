export const tooltips = {
    customers: {
        search: `
Du kan søke med følgende prefix:
+v - Viser versjonsinformasjon
+l - Viser login info
+a - Viser aktive brukere istedet for brukere logget inn siste 3 mnd
Job:&#60;jobb navn> - Søker frem kunder som har utført denne fjernkommandoen
!Job:&#60;jobb navn> - Som over, men negert
Date:&#60;ddmmåååå> - Søker frem kunder som er opprettet dato >= denne datoen
!;Date:&#60;ddmmåååå> - Som over, men negert
File:&#60;filnavn> - Søker frem kunder som har denne filen under filkopiering
File:&#60;filnavn> - Som over, men negert
Msg:&#60;beskrivelse> - Søker frem kunder som har denne meldingen under meldinger
!Msg:&#60;beskrivelse> - Som over, men negert
SU:&#60;søkestreng> - Søker blant kunder med ServerUpdateService
`,
        customer: {
            name: `dsfsdf`,
            maxUsers: `asdasd`
        }

    }
}