import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DatalexClient, ICustomerBE } from '@datalex-software-as/datalex-client';
import { IGridCellEventArgs, IgxInputGroupComponent, IgxInputDirective, IgxTooltipTargetDirective, IgxLabelDirective, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxGridFooterComponent, IgxFilterCellTemplateDirective, IgxTooltipDirective } from '@infragistics/igniteui-angular';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { ModalHubAccessService } from '../../UI/modal-hub/access.service';
import { ModalId } from 'src/app/util/ModalTypeIdEnum';
import { tooltips } from 'src/app/util/ToolTipsUtil';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { Subscription } from 'rxjs';
import { GridFilterInputComponent } from '../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../UI/grid-item-count/grid-item-count.component';
import { NgIf } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  standalone: true,
  imports: [IgxInputGroupComponent, IgxInputDirective, IgxTooltipTargetDirective, IgxLabelDirective, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, NgIf, IgxGridFooterComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxTooltipDirective]
})
export class CustomersComponent implements OnInit {

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  private subscriptions = new Subscription();

  constructor(public screen: ScreenDimensionService, private access: ModalHubAccessService, private dlxClient: DatalexClient, public screenSize: ScreenSizeService, public deviceService: DeviceService) {
    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));
  }

  ngOnDestroy() {
    // Clean up subscriptions to prevent memory leaks
    this.subscriptions.unsubscribe();
  }

  data: ICustomerBE[] = [];
  public toolTips = tooltips.customers;


  ngOnInit(): void {
    this.dlxClient.GetCustomersMaster().subscribe({
      next: (res) => {
        this.data = res
      }
    })
  }

  onRowSelected(event: IGridCellEventArgs) {
    this.access.openModal({ type: ModalId.Customer, data: event.cell.row.data })
    event.cell.grid.clearCellSelection();
  }

  searchCustomer(ev: HTMLInputElement) {
    const { value } = ev;
    this.dlxClient.SearchCustomers(value).subscribe({
      next: (res) => {
        this.data = res
      }
    })
  }

  // reset(ev: HTMLInputElement) {
  //   ev.value = "";
  //   this.dlxClient.GetCustomersMaster().subscribe({
  //     next: (res) => {
  //       this.data = res
  //     }
  //   })
  // }



}









