export enum ModalId {
        HourRegistration = '66325-01',
        DocumentUpload = '66325-02',
        DocumentDetails = '66325-03',
        ProfilePicture = '66325-04',
        DocumentFromTemplate = '66325-05',
        ContactSearch = '66325-06',
        DocumentCategory = '66325-07',
        Customer = '66325-08',

}